<template>
  <div>
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col lg="6" class="text-center text-sm-left mb-sm-0">
          <h1 class="header-main text-uppercase mb-0">รายละเอียดเซ็คชั่น</h1>
        </b-col>
      </CRow>

      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row>
          <b-col cols="6">
            <InputText
              textFloat="ชื่อเซ็คชั่น"
              placeholder="ชื่อเซ็คชั่น"
              type="text"
              name="name"
              isRequired
              v-model="form.name"
              :isValidate="$v.form.name.$error"
              :v="$v.form.name"
              class="mt-1"
          /></b-col>
          <b-col cols="6">
            <div class="d-flex flex-column mt-1">
              <label for="" class="w-50"
                >ประเภทเซ็คชั่น <span class="text-danger">*</span></label
              >
              <div class="d-flex">
                <b-form-radio class="" v-model="form.typeId" :value="0"
                  >สินค้า</b-form-radio
                >
                <b-form-radio class="ml-4" v-model="form.typeId" :value="1"
                  >ภาพ</b-form-radio
                >
                <b-form-radio class="ml-4" v-model="form.typeId" :value="2"
                  >คอลเลคชั่น</b-form-radio
                >
              </div>
            </div></b-col
          >
        </b-row>
      </b-container>

      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <CRow class="no-gutters px-3 px-sm-0">
          <b-col>
            <div class="d-flex flex-column mb-4">
              <label for="" class="w-50"
                >ขนาดแบนเนอร์ <span class="text-danger">*</span></label
              >
              <div class="d-flex">
                <b-form-radio class="" name="isCodeRequired" :value="false"
                  >ขนาดมาตรฐาน</b-form-radio
                >
                <b-form-radio class="ml-4" name="isCodeRequired" :value="true"
                  >16:9</b-form-radio
                >
              </div>
            </div></b-col
          >
          <b-col>
            <b-button @click="openModal()" class="btn-main float-right mt-3"
              >เพิ่มภาพ</b-button
            >
          </b-col>
        </CRow>
        <div class="mt-3 bg-white px-0 pb-3">
          <b-row class="no-gutters">
            <b-col>
              <b-table
                striped
                responsive
                hover
                :items="form.listItem"
                :fields="fields"
                :busy="isBusy"
                show-empty
                empty-text="ไม่พบข้อมูล"
                class="table-list"
              >
                <template v-slot:cell(imageUrl)="data">
                  <div
                    class="thumbnail-table ratio-10-2-pb"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.imageUrl + ')',
                    }"
                    v-if="data.item.isVideo != true"
                  ></div>
                  <div
                    v-else
                    class="position-relative p-0 embed-responsive embed-ratio-10-2-pb"
                  >
                    <video class="w-100 videos" controls>
                      <source :src="data.item.imageUrl" type="video/mp4" />
                    </video>
                  </div>
                </template>
                <template v-slot:cell(sortOrder)="data">
                  <div v-if="data.item.sortOrder == 0">-</div>
                  <div v-else>{{ data.item.sortOrder }}</div>
                </template>
                <template v-slot:cell(updatedTime)="data">
                  <span>{{
                    new Date(data.item.updatedTime) | moment($formatDate)
                  }}</span>
                </template>
                <!-- <template v-slot:cell(enabled)="data">
                  <div v-if="data.item.enabled == true" class="text-success">
                    ใช้งาน
                  </div>
                  <div v-else class="text-danger">ไม่ใช้งาน</div>
                </template> -->
                <template v-slot:cell(id)="data">
                  <div class="d-flex justify-content-center">
                    <!-- <router-link
                        :to="'/product/banner/details/' + data.item.id"
                      >
                        <b-button variant="link" class="text-dark px-1 py-0">
                          แก้ไข
                        </b-button>
                      </router-link> -->
                    <b-button
                      variant="link"
                      class="text-dark px-1 py-0"
                      @click="deleteData(data.item)"
                    >
                      ลบ
                    </b-button>
                  </div>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="no-gutters px-3 px-sm-0">
            <b-col
              class="form-inline justify-content-center justify-content-sm-between"
            >
              <div class="d-sm-flex m-3">
                <b-pagination
                  v-model="filter.pageNo"
                  :total-rows="rows"
                  :per-page="filter.perPage"
                  class="m-md-0"
                  @change="pagination"
                  align="center"
                ></b-pagination>
              </div>
              <b-form-select
                class="mr-sm-3 select-page"
                v-model="filter.perPage"
                @change="hanndleChangeperPage"
                :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </div>
      </b-container>

      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row class="px-3">
          <b-col
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex">
              <label class="font-weight-bold main-label">
                การใช้งาน
                <span class="text-danger">*</span>
              </label>
              <div>
                <b-form-checkbox
                  switch
                  class="radio-active ml-3"
                  size="lg"
                  v-model="form.enabled"
                >
                  <span class="ml-2 main-label">
                    {{ form.enabled ? "เปิด" : "ปิด" }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
          <b-col class="d-flex justify-content-end align-items-center">
            <router-link :to="'/section-banner'">
              <b-button class="button btn-cancel ml-2"
                >ย้อนกลับ</b-button
              ></router-link
            >
            <b-button class="btn-main ml-3" @click="checkForm()"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>
    <ModalBanner ref="modalBanner" @handleData="handleData" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalBanner from "./ModalBanner.vue";
import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";

export default {
  name: "SectionBannerDetail",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalBanner,
    InputText,
  },
  data() {
    return {
      id: this.$route.params.id,
      modalMessage: "",
      fields: [
        {
          key: "imageUrl",
          label: "ภาพประกอบ",
          class: "w-200",
        },
        {
          key: "name",
          label: "ชื่อ",
          class: "w-200",
        },
        {
          key: "sortOrder",
          label: "ลำดับการแสดง",
          class: "w-200",
        },
        {
          key: "updatedTime",
          label: "วันที่อัพเดท",
          class: "w-100px",
        },
        // {
        //   key: "enabled",
        //   label: "สถานะการแสดง",
        //   class: "w-100px",
        // },
        {
          key: "id",
          label: "",
          class: "w-100px",
        },
      ],
      isBusy: false,
      rows: 0,
      filter: {
        status: [0, 1],
        search: "",
        perPage: 10,
        pageNo: 1,
        sortByOrderOrId: 0,
        highlightFilter: 0,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      form: {
        typeId: 1,
        listItem: [],
      },
      timer: null,
    };
  },
  validations: {
    form: {
      name: { required },
    },
  },
  created: async function () {
    this.$isLoading = false;
    await this.getList();
  },
  methods: {
    getList: async function () {
      if (this.id == 0) {
        this.$isLoading = true;
        return;
      }
      this.isBusy = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/CustomSection/GetDetail/${this.id}`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.form = resData.detail;
        this.isBusy = false;
      }
      this.$isLoading = true;
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.$nextTick(() => {
          let target = document.querySelector(".text-error");
          if (target) {
            let domRect = document
              .querySelector(".text-error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 250
            );
          }
        });
        return;
      }

      this.$isLoading = false;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CustomSection/CreateUpdate`,
        null,
        this.$headers,
        this.form
      );
      this.$isLoading = true;
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/section-banner`,
          });
        }, 2000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    handleData(data) {
      if (this.form.listItem.length > 0) {
        for (let index = 0; index < data.length; index++) {
          let find = this.form.listItem.findIndex((e) => {
            return e.id === data[index].id;
          });
          if (find === -1) this.form.listItem.push(data[index]);
        }
      } else {
        this.form.listItem = data;
      }
    },
    pagination(Page) {
      this.filter.pageNo = Page;
      this.getList();
    },
    hanndleChangeperPage(value) {
      this.filter.pageNo = 1;
      this.filter.perPage = value;
      this.getList();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.pageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.pageNo = 1;
      this.getList();
    },
    openModal() {
      this.$refs.modalBanner.show();
    },
    deleteData(value) {
      let find = this.form.listItem.findIndex((e) => {
        return e.id === value.id;
      });
      if (find != -1) this.form.listItem.splice(find, 1);
    },
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
}

.panel-input-serach {
  border: 1px solid #bcbcbc !important;
}
</style>
